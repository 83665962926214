<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_home_02.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  As habitações que foram sendo implantadas nas comunidades de
                  Mariana são exemplares típicos da casa rural mineira, que ao
                  mesmo tempo revela simplicidade e beleza integrada à paisagem.
                  O conjunto edificado de cada terreno, seja ele urbano ou
                  rural, possui características próprias do campo, relacionadas
                  aos cultivos agrícolas e criação de animais, juntamente com a
                  residência em si. A casa então, geralmente erguida em um platô
                  (terreno plano) natural ou executado por máquina
                  (retroescavadeira) e ferramentas manuais, a exemplo de pá e
                  picareta, faz parte do conjunto de construções como o puxado
                  (coberta) do fogão e do forno a lenha, do galinheiro, do
                  chiqueiro, do curral, do paiol, do moinho d’água.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_03.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa situada em um terreno de Pedras. Foto: Vistoria técnica
            realizada pela Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_04.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de um terreno em Campinas com o quintal produtivo em torno da
            casa. Foto: Vistoria técnica realizada pela Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_05.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa construída em espaço plano do terreno em Paracatu de Baixo,
            entre árvores frutíferas do quintal. Foto: Vistoria técnica
            realizada pela Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_06.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de um conjunto edificado em terreno de Pedras, com a casa à
            esquerda da imagem, o curral e o paiol à direita. Foto: Vistoria
            técnica realizada pela Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_07.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista do curral de um terreno em Pedras. Foto: Vistoria técnica
            realizada pela Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_08.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Quintal produtivo de uma casa em Paracatu de Baixo, situado em torno
            da casa. Vistoria técnica realizada pela Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Dos terrenos inclinados, é tirado proveito para construção do
                  porão, muitas vezes utilizado para abrigar equipamentos
                  agrícolas e guardar ferramentas, arreios e carros de boi. No
                  cômodo, era comum armazenar frutas para amadurecer e guardar o
                  café que durante o dia seria seco no terreiro. A casa mineira
                  rural antiga, nos casos em que é assobradada, é geralmente
                  estruturada por esteios robustos de madeira, resultando no
                  porão pelo declive do terreno, onde existem muros de arrimo de
                  pedra. O pavimento superior geralmente possui paredes de pau a
                  pique e piso de tábua corrida (assoalho de madeira).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_09.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de uma casa situada em Pedras, construída em terreno em
            declive, com aproveitamento do porão. Foto: Vistoria técnica da
            Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_10.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa assobradada situada em Paracatu de Baixo, construída com
            aproveitamento de porão. Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_11.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista do porão de uma casa de Paracatu de Baixo, com muro de arrimo
            de pedra, barrotes e assoalho de madeira. Foto: Vistoria técnica da
            Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_12.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista interna de uma casa assobradada em Pedras, com assoalho de
            madeira e paredes de pau a pique. Foto: Vistoria técnica da Cáritas,
            2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O espaço da casa é cercado pelo terreiro e quintal produtivo,
                  contendo horta próxima da cozinha, além do pomar com
                  diversidade de frutíferas. É recorrente que haja algum corpo
                  hídrico nas terras, e o espaço de morada é implantado próximo
                  a ele de modo a abastecer o próprio uso doméstico, como
                  irrigar os cultivos e dar de beber aos animais. Também é comum
                  a presença de tanques para criação de peixes, contribuindo
                  assim para as famílias terem alimentos saudáveis e disponíveis
                  o ano todo.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_13.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de quintal produtivo de um terreno em Campinas. Foto: Vistoria
            técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_14.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Poço de peixes situado no quintal de uma casa em Campinas. Foto:
            Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A posição (implantação) das casas e das construções de uso
                  rural das comunidades é feita de maneira a garantir a boa
                  insolação e ventilação não só do próprio conjunto edificado,
                  mas também a implantação é dotada de uma sagacidade que
                  permite aos cultivos da horta receber o sol no período
                  adequado, pela manhã, às árvores frutíferas a quantidade de
                  luz e sombra suficientes ao seu crescimento e de modo que as
                  copas de umas sirvam de cobertura para outras se
                  desenvolverem. As roças também têm seu lugar adequado na
                  ocupação desse terreno, de modo que possam ser devidamente
                  irrigadas (aguadas) em solo de boa umidade e rico em
                  nutrientes. Já a posição da casa evita áreas com umidade,
                  protegendo-a, assim, da degradação (apodrecimento por umidade
                  ascendente, que vem do solo pela fundação, chegando às paredes
                  e ao piso).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_15.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de uma casa construída sobre fundação de pedras, isolando as
            paredes do solo. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_16.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de um quintal de terreno situado em Paracatu de Baixo, com o
            galinheiro e o paiol próximos da casa e da horta. Foto: Vistoria
            técnica da Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A sabedoria dos moradores das comunidades posiciona cada
                  construção, cada sistema produtivo no local que promova o bom
                  funcionamento desse sistema. Os cultivos, por sua vez, ocupam
                  as áreas de baixada, já que o solo nessas extensões é rico em
                  nutrientes e enriquecido pelo material que a chuva traz das
                  encostas, contribuindo com a adubação natural.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_17.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Cultivo de roças na área de baixada em um terreno de Camargos,
            próximo ao córrego. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_18.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de um quintal de terreno situado em Camargos, onde se observa
            o jardim florido em meio a outros cultivos. Foto: Vistoria técnica
            da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Ao mesmo tempo modesta, sólida e espaçosa, a residência é
                  feita com materiais coletados na região, no próprio terreno,
                  predominantemente utilizando técnicas que fazem parte do
                  repertório dos moradores locais, que foram aprendendo com seus
                  pais, avós e transmitindo de uma geração a outra.
                </p>
                <p>
                  Apesar da riqueza relativa à extração do ouro no período
                  colonial, as edificações destinadas à habitação,
                  principalmente nas áreas rurais, eram caracterizadas pela
                  simplicidade de formas e organização interna dos cômodos, e
                  assim seguiram pelos séculos seguintes. Com planta retangular,
                  geralmente a entrada ocorre pela porção frontal do terreno,
                  com acesso para a sala. As portas dos quartos são voltadas
                  para ela, assim como a da cozinha. Não menos importante é a
                  comunicação deste ambiente com o quintal, havendo na parte
                  inferior do vão da porta uma cancela para impedir a entrada de
                  pequenos animais. A cozinha é o coração da casa e nela
                  sobressai-se o
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudo_soberania_beira_fogao' }"
                    >fogão a lenha</router-link
                  >.
                </p>
                <p style="text-align: center">
                  <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_19.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da cozinha de uma casa em Paracatu de Baixo. Foto: Vistoria
            técnica da Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_20.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da porta da cozinha de uma casa em Paracatu de Baixo, voltada
            para o quintal, com a cancela para evitar a entrada de animais.
            Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_21.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da sala de uma casa construída na comunidade de Camargos.
            Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_23.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista do quintal de um terreno em Ponte do Gama. Foto: Vistoria
            técnica da Cáritas, 2020.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_24.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Detalhe do interior de uma casa construída na comunidade de Pedras.
            Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_jardins_quintais' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Os jardins e os quintais"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casas /",
          href: "conteudos_tematicos_casas",
        },
        {
          text: "Espaços de Moradia",
          href: "conteudos_tematicos_casas_espaco_moradia",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }
  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
